import React, { useState } from "react"

const SelectField = (props) => {
  const [source, setSource] = useState('')

  const updateSource = (event) => { setSource(event.target.value) }

  const sourceColor = source ? "charcoal" : "tc-gray"

  const { name, options, onChange } = props

  return (
    <div className="form-input--select_container">
      <select id={name} name={name} className={`form-input--select input-reset border-grey ${sourceColor}`} defaultValue={""} onChange={onChange || updateSource}>
        <option value="" disabled>Select</option>
        {options.map((option) =>
        <option key={option.value} value={option.value}> {option.label || option.value} </option>
      )}
      </select>
      <div className="flex justify-center items-center ph3 events-none absolute right-0 top-0 bottom-0">
        <i className="fa fa-lg fa-chevron-down charcoal mh1"></i>
      </div>
    </div>
  )
}

export default SelectField